import React from "react"

import { useImage } from "../../../hooks/useImage"

export const withActivate =
  Component =>
  ({ name = "Activate", page }) => {
    const { getGatsbyImage } = useImage()

    const { title, image: originalImage } = page || {}

    const image = getGatsbyImage(originalImage)

    Component.displayName = name
    return <Component title={title} image={image} />
  }
