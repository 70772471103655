import React from "react"

import { withActivateForm } from "./withActivateForm"
import { TextField } from "../../../TextField/TextField"
import { Submit, Errors, Error, Description } from "../../Layout/LayoutStyles"

export const ActivateForm = withActivateForm(
  ({
    data,
    handleChange,
    handleSubmit,
    loading,
    initError,
    errors,
    isInvalid,
    description,
    errorMessage,
    additionalSubmitButton,
    additionalPasswordLabel,
    additionalConfirmPasswordLabel,
  }): JSX.Element => (
    <>
      {initError ? (
        <Errors>
          <Error>{initError}</Error>
        </Errors>
      ) : (
        <Description>{description}</Description>
      )}
      {!initError && (
        <form onSubmit={handleSubmit}>
          <TextField
            name="password"
            type="password"
            value={data?.password}
            onChange={handleChange}
            label={additionalPasswordLabel}
            required
            spacing
          />
          <TextField
            name="confirmedPassword"
            type="password"
            value={data?.confirmedPassword}
            onChange={handleChange}
            label={additionalConfirmPasswordLabel}
            required
            spacing
          />
          {errors?.length > 0 && (
            <Errors>
              {errors?.map((error, index) => (
                <Error key={index}>
                  <p>{error?.message}</p>
                </Error>
              ))}
            </Errors>
          )}
          {isInvalid ? (
            <Errors>
              <Error>{errorMessage}</Error>
            </Errors>
          ) : null}
          <Submit size="primary" wide="true" type="submit" disabled={loading}>
            {additionalSubmitButton}
          </Submit>
        </form>
      )}
    </>
  ),
)
