import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../../hooks/useApp"
import { useCustomerAccount } from "../../../../hooks/useCustomer"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withActivateForm = Component => ({ name = "ActivateForm" }) => {
  const {
    config: {
      settings: { params },
    },
  } = useApp()
  const {
    activateCustomer,
    data,
    setData,
    loading,
    errors,
  } = useCustomerAccount()
  const { getUrlParameter } = useRoutes()

  const { activate } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_ACTIVATE {
      activate: sanityPageAccountActivate {
        description
        message
        errorMessage
        additionalSubmitButton
        additionalPasswordLabel
        additionalConfirmPasswordLabel
      }
    }
  `)

  const {
    description,
    message,
    errorMessage,
    additionalSubmitButton,
    additionalPasswordLabel,
    additionalConfirmPasswordLabel,
  } = activate || {}

  const { customerId, activationToken, initError } = useMemo(() => {
    const activateParams = getUrlParameter(params?.customer)?.split(`/`) || []

    const customerId = activateParams?.[0] || null
    const activationToken = activateParams?.[1] || null

    let initError = null

    if (!customerId || !activationToken) {
      initError = message
    }

    return { customerId, activationToken, initError }
  }, [getUrlParameter, params?.customer, message])

  const handleSubmit = async event => {
    event.preventDefault()
    await activateCustomer(customerId, activationToken, data?.password)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const isInvalid = data.password !== data.confirmedPassword

  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      initError={initError}
      errors={errors}
      isInvalid={isInvalid}
      description={description}
      errorMessage={errorMessage}
      additionalSubmitButton={additionalSubmitButton}
      additionalPasswordLabel={additionalPasswordLabel}
      additionalConfirmPasswordLabel={additionalConfirmPasswordLabel}
    />
  )
}
