import React from "react"

import { withActivate } from "./withActivate"
import { ActivateForm } from "./Form/ActivateForm"
import { Layout } from "../Layout/Layout"

export const Activate = withActivate(
  ({ title, image }): JSX.Element => (
    <Layout title={title} image={image}>
      <ActivateForm />
    </Layout>
  )
)
